import { useState } from 'react';

const TimeInput = ({ name, required, className, onChange }) => {
  const [time, setTime] = useState({ hour: '', minute: '', ampm: 'AM' });

  const handleHourInput = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (parseInt(value) > 12) value = '12';
    setTime((prevTime) => ({ ...prevTime, hour: value }));
    handleChange(value, time.minute, time.ampm);
  };

  const handleMinuteInput = (e) => {
    let value = e.target.value.replace(/\D/g, '');
    if (parseInt(value) > 59) value = '59';
    setTime((prevTime) => ({ ...prevTime, minute: value }));
    handleChange(time.hour, value, time.ampm);
  };

  const handleAmpmChange = (e) => {
    const value = e.target.value;
    setTime((prevTime) => ({ ...prevTime, ampm: value }));
    handleChange(time.hour, time.minute, value);
  };

  const handleChange = (hour, minute, ampm) => {
    if (hour && minute) {
      let convertedHour = parseInt(hour);
      if (ampm === 'PM' && convertedHour < 12) {
        convertedHour += 12; // Convert PM hour to 24-hour format
      } else if (ampm === 'AM' && convertedHour === 12) {
        convertedHour = 0; // Convert 12 AM to 00 (midnight)
      }

      const time24 = `${String(convertedHour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`;
      onChange({ target: { name: `${name}_time`, value: time24 } });
    }
  };

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <input
        type="text"
        name={`${name}_hour`}
        placeholder="HH"
        required={required}
        className="w-12 p-2 border rounded text-center"
        maxLength={2}
        value={time.hour}
        onChange={handleHourInput}
      />
      <span className="text-xl">:</span>
      <input
        type="text"
        name={`${name}_minute`}
        placeholder="MM"
        required={required}
        className="w-12 p-2 border rounded text-center"
        maxLength={2}
        value={time.minute}
        onChange={handleMinuteInput}
      />
      <select
        name={`${name}_ampm`}
        required={required}
        className="p-2 border rounded"
        value={time.ampm}
        onChange={handleAmpmChange}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </select>
    </div>
  );
};

export default TimeInput;
