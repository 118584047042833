import React from "react";
import CheckboxGridTF from "../components/common/checkboxGridTF";
import LinearScaleTF from "../components/common/LinearScaleTF";
import MultipleChoiceGridTF from "../components/common/multipleChoiceGridTF";
import TimeInput from "../components/common/TimeInput";
import { Check } from "lucide-react";

const hexToRgb = (hex) => {
  if (typeof hex !== 'string') return '0, 0, 0';
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const autoResizeTextarea = (e) => {
  e.target.style.height = 'auto';
  e.target.style.height = `${e.target.scrollHeight}px`;
};

const renderInputTF = (question,answers,handleInputChange,handleCheckboxChange,otherAnswers,handleOtherInputChange,gridAnswers,handleMultipleChoiceGridSelection,handleCheckboxGridSelection,preferences) => {
    const inputClasses = `w-full px-4 py-2 bg-white border-b border-gray-300 focus:outline-none focus:border-b-[3px] focus:border-[var(--border-color)]`;
    const labelClasses = "inline-flex items-center cursor-pointer mr-3";
    const radioCheckboxClasses = "form-radio h-5 w-5 text-blue-600 border-gray-300";
    // console.log(question.question);
    // console.log(question.type);
    console.log(question);
    switch (question.type) {
      case 'text':
      case 'short_answer':
        return (
          <input
            type={question.analyzedType === 'email' ? 'email' : question.analyzedType === 'tel' ? 'tel' : 'text'}
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
            style={{
              '--border-color': preferences?.buttonColor || '#000',
            }}
            placeholder={
              question.analyzedType === 'email' ? 'Enter your email' :
                question.analyzedType === 'tel' ? 'Enter your phone number' :
                  'Type your answer here'
            }
          />
        );
        case 'textarea':
        case 'paragraph':
          return (
            <textarea
              name={question.entry}
              value={answers[question.entry] || ''}
              onChange={(e) => {
                handleInputChange(e, question.entry);
                autoResizeTextarea(e);
              }}
              required={question.required}
              rows="1"
              className={inputClasses}
              style={{
                '--border-color': preferences?.buttonColor || '#000',
                overflow: 'hidden', resize: 'none'
              }}
              placeholder="Type your answer here"
            ></textarea>
          );
          case 'select':
            case 'multiple_choice':
              return (
                <>
                <div className="space-y-2" style={{ width: 'fit-content',minWidth: '25%' }}>
                  {question.options.map((option, index) => (
                    <label 
                      key={option.value} 
                      className={`flex items-center justify-between min-w-1/4 space-x-3 cursor-pointer rounded-md border-[1px] p-2`}
                      style={{
                        backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
                        boxShadow: `inset 0px 0px 0px 1px rgba(${hexToRgb(preferences?.buttonColor)}, 0.6)`,
                      }}
                    >
                      <span className={`text-sm font-medium px-2 h-6 border-[1px] bg-white rounded-sm`}
                        style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                      >
                        {String.fromCharCode(65 + index)}
                      </span>
                      <span className="text-sm w-full font-medium min-h-6 h-auto"
                        style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                      >{option.value}</span>
                      <div className="flex relative">
                        <input
                          type="checkbox"
                          name={question.entry}
                          value={option.value}
                          checked={Array.isArray(answers[question.entry]) && answers[question.entry].includes(option.value)}
                          onChange={(e) => handleCheckboxChange(e, question.entry)}
                          className="appearance-none w-5 h-5 rounded focus:outline-none"
                        />
                        {Array.isArray(answers[question.entry]) && answers[question.entry].includes(option.value) && (
                          <Check
                            className="absolute top-0 left-0 w-5 h-5 transition duration-200" style={{color: preferences?.buttonColor}}/>
                        )}
                      </div>
                    </label>
                  ))}
                  {question.hasOtherOption && (
                    <div className="mt-4">
                      <label className={`flex items-center min-w-1/4 justify-between space-x-3 cursor-pointer rounded-md border-[1px] p-2`}
                        style={{
                          backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
                          boxShadow: `inset 0px 0px 0px 1px rgba(${hexToRgb(preferences?.buttonColor)}, 0.6)`,
                        }}
                      >
                        <span className={`text-sm font-medium px-2 h-6 border-[1px] bg-white rounded-sm`}
                          style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                        >
                          {String.fromCharCode(65 + question.options.length)}
                        </span>
                        <span className="text-sm w-full font-medium min-h-6 h-auto"
                          style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                        >Other</span>
                        <div className="flex relative">
                          <input
                            type="checkbox"
                            name={question.entry}
                            value="Other"
                            checked={Array.isArray(answers[question.entry]) && answers[question.entry].includes('Other')}
                            onChange={(e) => handleCheckboxChange(e, question.entry)}
                            className="appearance-none w-5 h-5 rounded focus:outline-none"
                          />
                          {Array.isArray(answers[question.entry]) && answers[question.entry].includes('Other') && (
                            <Check className="absolute top-0 left-0 w-5 h-5 transition duration-200" style={{color: preferences?.buttonColor}}/>
                          )}
                        </div>
                      </label>
                      
                    </div>
                  )}
                </div>
                  {question.hasOtherOption && (<input
                    type="text"
                    value={otherAnswers[question.entry] || ''}
                    onChange={(e) => handleOtherInputChange(e, question.entry)}
                    className={inputClasses}
                    style={{
                      '--border-color': preferences?.buttonColor || '#000',
                    }}
                    disabled={!Array.isArray(answers[question.entry]) || !answers[question.entry].includes('Other')}
                    placeholder="Please specify other option"
                  />)}
                </>
              );
            case 'radio':
              return (
                <>
                <div className="space-y-2" style={{ width: 'fit-content',minWidth: '25%'}}>
                  {question.options.map((option, index) => (
                    <label 
                      key={option.value} 
                      className={`flex items-center justify-between min-w-1/4 space-x-3 cursor-pointer rounded-md border-[1px] p-2`}
                      style={{
                        backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
                        boxShadow: `inset 0px 0px 0px 1px rgba(${hexToRgb(preferences?.buttonColor)}, 0.6)`,
                      }}
                    >
                      <span className={`text-sm font-medium px-2 h-6 border-[1px] bg-white rounded-sm`}
                        style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                      >
                        {String.fromCharCode(65 + index)}
                      </span>
                      <span className="text-sm w-full font-medium min-h-6 h-auto"
                        style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                      >{option.value}</span>
                      <div className="flex relative">
                        <input
                          type="radio"
                          name={question.entry}
                          value={option.value}
                          checked={answers[question.entry] === option.value}
                          onChange={(e) => handleInputChange(e, question.entry)}
                          required={question.required}
                          className="appearance-none w-5 h-5 rounded-full focus:outline-none"
                        />
                        {answers[question.entry] === option.value && (
                          <Check className="absolute top-0 left-0 w-5 h-5 transition duration-200" style={{color: preferences?.buttonColor}}/>
                        )}
                      </div>
                    </label>
                  ))}
                  {question.hasOtherOption && (
                    <div className="mt-2">
                      <label 
                        className={`flex items-center justify-between min-w-1/4 space-x-3 cursor-pointer rounded-md border-[1px] p-2`}
                        style={{
                          backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
                          boxShadow: `inset 0px 0px 0px 1px rgba(${hexToRgb(preferences?.buttonColor)}, 0.6)`,
                        }}
                      >
                        <span className={`text-sm font-medium px-2 h-6 border-[1px] bg-white rounded-sm`}
                          style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                        >
                          {String.fromCharCode(65 + question.options.length)}
                        </span>
                        <span className="text-sm w-full font-medium min-h-6 h-auto"
                          style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor, borderColor: preferences?.buttonColor}}
                        >Other</span>
                        <div className="flex relative">
                          <input
                            type="radio"
                            name={question.entry}
                            value="__other_option__"
                            checked={answers[question.entry] === '__other_option__'}
                            onChange={(e) => handleInputChange(e, question.entry)}
                            className="appearance-none w-5 h-5 rounded-full focus:outline-none"
                          />
                          {answers[question.entry] === '__other_option__' && (
                            <Check className="absolute top-0 left-0 w-5 h-5 transition duration-200" style={{color: preferences?.buttonColor}}/>
                          )}
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                {question.hasOtherOption && (
                  <input
                        type="text"
                        value={otherAnswers[question.entry] || ''}
                        onChange={(e) => handleOtherInputChange(e, question.entry)}
                        className={inputClasses}
                        style={{
                          '--border-color': preferences?.buttonColor || '#000',
                        }}
                        disabled={answers[question.entry] !== '__other_option__'}
                        placeholder="Please specify"
                      />
                )}
                </>
              );
      case 'checkboxes':
        return (
          <div className="space-y-2">
            {question.options.map((option) => (
              <label key={option.value} className={`${labelClasses} block`}>
                <input
                  type="checkbox"
                  name={question.entry}
                  value={option.value}
                  checked={(answers[question.entry] || []).includes(option.value)}
                  onChange={(e) => handleInputChange(e, question.entry)}
                  className={`${radioCheckboxClasses} mr-2`}
                />
                <span>{option.value}</span>
              </label>
            ))}
            {question.hasOtherOption && (
              <div className="mt-2">
                <label className={`${labelClasses} block`}>
                  <input
                    type="checkbox"
                    name={question.entry}
                    value="Other"
                    checked={(answers[question.entry] || []).includes('Other')}
                    onChange={(e) => handleInputChange(e, question.entry)}
                    className={`${radioCheckboxClasses} mr-2`}
                  />
                  <span>Other:</span>
                </label>
                <input
                  type="text"
                  value={otherAnswers[question.entry] || ''}
                  onChange={(e) => handleOtherInputChange(e, question.entry)}
                  className={`${inputClasses} mt-2`}
                  disabled={!answers[question.entry] || !answers[question.entry].includes('Other')}
                  placeholder="Specify other option"
                />
              </div>
            )}
          </div>
        );
      case 'dropdown':
        return (
          <select
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            className={inputClasses}
          >
            <option value="">Select an option</option>
            {question.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        );
    //   case 'file_upload':
    //     return (
    //       <input
    //         type="file"
    //         name={question.entry}
    //         onChange={(e) => handleFileUpload(e, question.entry)}
    //         required={question.required}
    //         className={inputClasses}
    //         accept={question.fileTypes.join(',')}
    //       />
    //     );
      case 'linear_scale':
        return (
          <LinearScaleTF
            question={question}
            answers={answers}
            handleInputChange={handleInputChange}
            preferences={preferences}
          />
        );
      case 'multiple_choice_grid':
        return (
          <div>
            <MultipleChoiceGridTF
              question={{
                ...question,
                rows: question.rows.map(row => ({
                  entry: row.entry || row,
                  value: row.value || row
                })),
                options: question.options.map(option => ({
                  value: option.value || option
                }))
              }}
              gridAnswers={gridAnswers}
              handleMultipleChoiceGridSelection={handleMultipleChoiceGridSelection}
              preferences={preferences}
            />
          </div>
        );

        case 'checkbox-grid':
          // console.log('Rendering checkbox-grid:', question);
          return (
            <div>
              {/* <h3 className="text-lg font-semibold mb-2">{question.question}</h3> */}
              {question.rows && question.options ? (
                <CheckboxGridTF
                  question={{
                    ...question,
                    rows: question.rows.map(row => ({
                      entry: row.entry || row,
                      value: row.value || row
                    })),
                    options: question.options.map(option => ({
                      value: option.value || option
                    }))
                  }}
                  gridAnswers={gridAnswers}
                  handleCheckboxGridSelection={handleCheckboxGridSelection}
                  preferences={preferences}
                />
              ) : (
                <p className="text-red-500">Error: Invalid question structure for checkbox-grid</p>
              )}
            </div>
          );
      case 'date':
        return (
          <input
            type="date"
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            // className={inputClasses}
            className={`w-1/4 p-2 border-[1px] border-black rounded-md`}
          />
        );
      case 'time':
        return (
          <TimeInput
            name={question.entry}
            value={answers[question.entry] || ''}
            onChange={(e) => handleInputChange(e, question.entry)}
            required={question.required}
            // className={inputClasses}
          />
        );
      case 'section':
        return (
          <div className="mb-4">
            <h2 className="text-xl font-bold">{question.title}</h2>
            <p>{question.description}</p>
          </div>
        );
      default:
        return null;
    }
};

export default renderInputTF;