import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser, useAuth } from '@clerk/clerk-react';
import { toast } from 'react-toastify';
import { Loader2, Info } from 'lucide-react';
import useApi from '../utils/api';
import FormCard from '../components/FormCard';
import FormCardSkeletonLoader from '../components/FormCardPlaceholder';
import ClassicTemplate from "../../src/components/assets/classic.png";
import InteractiveTemplate from "../../src/components/assets/interactive.png";
import comingTemplate from "../../src/components/assets/coming-soon.png";
import PricingModal from '../../src/components/common/Pricing';

const DashboardLayout = () => {
  const [formUrl, setFormUrl] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState('Interactive');
  const [error, setError] = useState(null);
  const [enhancedForms, setEnhancedForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [submitLoad, setSubmitLoad] = useState(false);
  const navigate = useNavigate();
  const { user, isSignedIn } = useUser();
  const [isPro, setIsPro] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { getToken } = useAuth();
  const api = useApi();

  useEffect(() => {
    const token = getToken();
    const checkSubscription = async () => {
      const response = await api.get(`/check-subscription/${user.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      if(!response){
        throw new Error("failed to fetch pro status");
      }
      const status = response.data.status;
      setIsPro(status);
    };

    if(isSignedIn) checkSubscription();
  }, [setIsPro, user, getToken, isSignedIn])

  useEffect(() => {
    const fetchEnhancedForms = async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const response = await api.get('/user-enhanced-forms', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setEnhancedForms(response.data);
      } catch (error) {
        console.error('Error fetching enhanced forms:', error);
        setError('Failed to fetch enhanced forms');
      } finally {
        setLoading(false);
      }
    };

    fetchEnhancedForms();
  }, [getToken]);

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setError(null);
    setLoading(true);
    setSubmitLoad(true);
    try {
      const token = await getToken();

      const fetchResponse = await api.get(`/fetch-form?url=${encodeURIComponent(formUrl)}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const formData = fetchResponse.data;

      if (!formData || !formData.sections || !formData.originalForm) {
        throw new Error('Invalid form data received from server');
      }

      const enhancedFormId = formData.originalForm.id.replace('e/', '');
      const enhancedFormData = {
        originalForm: {
          id: formData.originalForm.id,
          url: formData.originalForm.url
        },
        enhancedForm: {
          id: enhancedFormId,
          publicUrl: `${window.location.origin}/public-form/${enhancedFormId}?template=${selectedTemplate}`,
          adminUrl: `${window.location.origin}/form/${enhancedFormId}?template=${selectedTemplate}`
        },
        description: formData.description,
        template: selectedTemplate,
        title: formData.title
      };

      const saveResponse = await api.post('/create-enhanced-form', enhancedFormData, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setSubmitLoad(false);

      if(saveResponse.status >= 200 && saveResponse.status < 300){
        navigate(`/form/${enhancedFormData.enhancedForm.id}?template=${selectedTemplate}`, {
          state: {
            enhancedFormData: saveResponse.data.enhancedForm,
            originalFormData: formData
          },
          replace: true
        });
      } else {
        toast.error("Form already created");
      }
    } catch (error) {
      console.error('Error creating enhanced form:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
        toast.error(error.response.data.error);
      } else {
        setError('Failed to create enhanced form');
        toast.error('Failed to create enhanced form');
      }
    } finally {
      setLoading(false);
      setSubmitLoad(false);
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleDeleteForm = async (formId) => {
    try {
      const token = getToken();
      const response = await api.delete(`/delete-form/${formId}`, {
        headers: {Authorization: `Bearer ${token}`}
      });
      if (response.data.success) {
        setEnhancedForms(enhancedForms.filter(form => form._id !== formId));
      } else {
        toast.error('Failed to delete form');
      }
    } catch(err) {
      console.error('Error deleting form:', err);
      toast.error('Failed to delete form');
    }
  };

  const thumbnails = {
    'Classic': ClassicTemplate,
    'Interactive': InteractiveTemplate,
    'Typeform': comingTemplate
  };

  return (
    <div className="max-w-3xl mx-auto mt-10 p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">Let's enhance your Google Form</h1>
      </div>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-8 border border-ng-100">
        <form onSubmit={handleFormSubmit} className="space-y-6">
          <div>
            <label htmlFor="formUrl" className="block mb-2 text-sm font-medium text-gray-700">
              Enter your public Google form URL
            </label>
            <input
              id="formUrl"
              type="text"
              value={formUrl}
              onChange={(e) => setFormUrl(e.target.value)}
              placeholder="https://docs.google.com/forms/d/e/..."
              className="w-full border border-gray-300 rounded-xl px-4 py-3 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-700">Select template</label>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {Object.entries(thumbnails).map(([template, thumbnailSrc]) => {
                return (
                  <div
                    key={template}
                    onClick={() => handleTemplateSelect(template)}
                    className={`py-2 px-2 border rounded-xl transition duration-300 cursor-pointer ${
                      selectedTemplate === template ? 'border-indigo-500 ring-2 ring-indigo-500' : 'border-gray-200 hover:border-indigo-300'
                    }`}
                  >
                    <div className="aspect-w-16 aspect-h-9 mb-2 rounded-md overflow-hidden">
                      <img
                        src={thumbnailSrc}
                        alt={`${template} template`}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <p className={`text-center text-sm font-medium`}>
                      {template}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {(isPro === false && enhancedForms.length === 1) ? (
            <div>
              <button
                type="button"
                onClick={handleModalToggle}
                className="w-full bg-indigo-600 text-white hover:bg-indigo-700 py-3 px-4 rounded-md transition duration-300 text-sm font-medium flex items-center justify-center"
              >
                Create enhanced form
              </button>
              <PricingModal isOpen={isModalOpen} onClose={handleModalToggle} />
            </div>
          ) : (
            <button
              type="submit"
              disabled={submitLoad}
              className={`w-full bg-indigo-600 text-white hover:bg-indigo-700 py-3 px-4 rounded-md transition duration-300 text-sm font-medium flex items-center justify-center ${
                submitLoad ? 'opacity-75 cursor-not-allowed' : ''
              }`}
            >
              {submitLoad ? (
                <>
                  <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                  Creating...
                </>
              ) : (
                'Create enhanced form'
              )}
            </button>
          )}
          
          {/* New disclaimer section */}
          <div className="mt-4 p-4 bg-gray-100 rounded-md flex items-start">
            <Info className="w-5 h-5 text-gray-500 mr-2 flex-shrink-0 mt-0.5" />
            <p className="text-sm text-gray-600">
              As of now, we do not support grid question types, file storage and forms which require SignIn. We plan to add some of these features in the coming weeks. Thank you for your patience.
            </p>
          </div>
        </form>
      </div>

      <div className="bg-white p-1">
        <h2 className="text-xl font-bold mb-6">Your enhanced forms</h2>
        {loading ? (
          Array(2).fill().map((_, index) => <FormCardSkeletonLoader key={index} />)
        ) : enhancedForms.length === 0 ? (
          <p className="text-gray-600">You haven't created any enhanced forms yet.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {enhancedForms.map((form) => (
              <FormCard
                key={form._id}
                form={form}
                handleDeleteForm={handleDeleteForm}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;