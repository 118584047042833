import React, {useState, useRef, useEffect} from 'react';
import { DotsVerticalIcon, EyeIcon, ClipboardCopyIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';

const handleCopyUrl = (url) => {
    navigator.clipboard.writeText(url);
    toast.success('URL copied to clipboard!');
};

const handleViewForm = (enhancedFormUrl) => {
    window.open(enhancedFormUrl, '_blank');
};

const FormCard = ({ form,handleDeleteForm }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dropdownRef = useRef(null);
  const modalRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const confirmDelete = () => {
    handleDeleteForm(form._id);
    setShowModal(false);
    setIsOpen(false);
  };

    return (
      <div className="border border-gray-200 rounded-xl p-4 shadow-sm max-w-md">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-semibold truncate">{form.title}</h3>
          <div className="relative" ref={dropdownRef}>
            <button className="text-gray-500 hover:text-gray-700" onClick={toggleDropdown}>
              ⋮
            </button>
            {isOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-sm border border-ng-100 z-10">
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setShowModal(true)}
                >
                  Delete
                </button>
                <a
                  href={form.originalForm.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setIsOpen(false)}
                >
                  Original Form URL
                </a>
              </div>
            )}
            {showModal && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl">
                  <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
                  <p className="mb-6">Are you sure you want to delete this form? This action cannot be undone.</p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                      onClick={() => setShowModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                      onClick={confirmDelete}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <p className="text-sm text-gray-600 mb-2 truncate">{form.shortUrl}</p>
        <p className="text-sm text-gray-600 mb-4">
          Template: {form.template} | Clicks: {form.analytics ? form.analytics.clicks : 'N/A'} | Submissions: {form.submissions}
        </p>
        <div className="flex space-x-2">
          <button
            onClick={() => handleViewForm(form.enhancedForm.adminUrl)}
            className="flex items-center bg-indigo-600 text-white hover:bg-indigo-700 font-medium py-2 px-3 rounded-lg text-sm transition duration-300"
          >
            <EyeIcon className="h-4 w-4 mr-1" />
            View form
          </button>
          <button
            onClick={() => handleCopyUrl(form.shortUrl)}
            className="flex items-center bg-gray-100 hover:bg-gray-200 text-gray-700 font-medium py-2 px-3 rounded-lg text-sm transition duration-300"
          >
            <ClipboardCopyIcon className="h-4 w-4 mr-1" />
            Copy
          </button>
        </div>
      </div>
    );
};

  export default FormCard;
