import React, { useState } from 'react';
import { useAuth,useUser } from '@clerk/clerk-react';
import useApi from "../../utils/api";
import { Loader2 } from 'lucide-react';


const PricingModal = ({ isOpen, onClose }) => {
    const [redirecting,setRedirecting] = useState(false);
    if (!isOpen) return null;
    const handleClickOutside = (e) => {
        if (e.target.id === 'modal-overlay') {
        onClose();
        }
    };

    const buySubscription = async() => {
        try{
        // console.log(user.id);
        // console.log(token);
        setRedirecting(true);
        const CHECKOUT_URL = process.env.REACT_CHECKOUT_URL || 'https://formlite-co.lemonsqueezy.com/buy/dcfa8a63-9767-4106-b27a-0f7fbb7130dc';
        // console.log(response);
        window.open(CHECKOUT_URL, '_blank');
        }
        catch(err){
        console.log(err);
        alert("failed to redirect");
        }
        finally{
            setRedirecting(false);
        }
    }

    return (
        <div
          id="modal-overlay"
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleClickOutside}
        >
          <div
            className="w-full max-w-md mx-auto bg-white rounded-lg shadow-sm overflow-hidden border bg-gray-100"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
          >
            <div className="p-4 sm:p-6">
                <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
                    <div className="mb-4 sm:mb-0">
                    <h3 className="text-lg sm:text-xl font-bold">One-time payment</h3>
                    <p className="text-gray-600">Access to all features, including future ones</p>
                    </div>
                    <div className="text-center sm:text-right">
                    <p className="text-gray-400 line-through">$99</p>
                    <p className="text-2xl sm:text-3xl font-bold">$69</p>
                    <p className="text-gray-600">One time payment</p>
                    </div>
                </div>
                <button
                className="w-full bg-indigo-600 text-white hover:bg-indigo-700 rounded-md py-2 text-lg flex justify-center items-center"
                onClick={buySubscription}
                >
                {redirecting ? (
                    <>
                    <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                    <span>Redirecting...</span>
                    </>
                ) : (
                    'Grab the deal!'
                )}
                </button>

            </div>
            <div className="bg-gray-50 p-4 py-2 sm:p-6">
              <h4 className="font-semibold mb-4">What are the limits of free plan?</h4>
              <p className="text-gray-600 mb-4">1 form with one template</p>
              <h4 className="font-semibold mb-4">What's included in One-time payment?</h4>
              <p className="text-gray-600 mb-4">Access to current and all upcoming features.</p>
              <h4 className="font-semibold mb-4">Does it support private forms?</h4>
              <p className="text-gray-600">
                No, as of now we support public forms. Ideal for public facing use cases like surveys,
                polls, waitlists, quizzes, etc.
              </p>
            </div>
          </div>
        </div>
    );
};

export default PricingModal;
