import React from 'react';

const hexToRgb = (hex) => {
  if (typeof hex !== 'string') return '0, 0, 0';
  const bigint = parseInt(hex.replace('#', ''), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

const CheckboxGridTF = ({ question, gridAnswers, handleCheckboxGridSelection, preferences }) => {
  if (!question.rows || !question.options || question.rows.length === 0 || question.options.length === 0) {
    console.error('Invalid question structure for CheckboxGrid:', question);
    return <p className="text-red-500">Error: Invalid question structure for checkbox grid</p>;
  }

  return (
    <div className="w-full">
      <div className="flex mb-4">
        <div className="w-1/3"></div>
        {question.options.map((option, index) => (
          <div key={`column-${index}`} className="flex-1 text-center">
            <span className="inline-block px-2 py-1 text-sm font-medium rounded"
              style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor}}
            >
              {option.value}
            </span>
          </div>
        ))}
      </div>
      <div className="space-y-3">
        {question.rows.map((row) => (
          <div 
            key={row.entry} 
            className="flex items-center rounded-lg"
            style={{
              backgroundColor: `rgba(${hexToRgb(preferences?.buttonColor)}, 0.1)`,
            }}
          >
            <div className="w-1/3 py-2 px-4">
              <span className="inline-block px-2 py-1 text-sm font-medium rounded" style={{color: preferences?.textColor === '#ffffff' ? '#000000' : preferences?.textColor}}>
                {row.value}
              </span>
            </div>
            {question.options.map((option) => (
              <div key={`${row.entry}-${option.value}`} className="flex-1 flex items-center justify-center py-2 px-4">
                <div className="relative inline-flex items-center justify-center">
                  <input
                    type="checkbox"
                    name={row.entry}
                    value={option.value}
                    checked={(gridAnswers[row.entry] || []).includes(option.value)}
                    onChange={() => handleCheckboxGridSelection(question.entry, row.entry, option.value)}
                    className="opacity-0 absolute h-5 w-5 cursor-pointer"
                  />
                  <span className="flex items-center justify-center h-5 w-5 border rounded-sm cursor-pointer" style={{borderColor: preferences?.buttonColor}}>
                    {(gridAnswers[row.entry] || []).includes(option.value) && (
                      <svg className="w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={preferences?.buttonColor}>
                        <rect width="20" height="20" rx="2" />
                        <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CheckboxGridTF;