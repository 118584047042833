import React from 'react';

const FormCardSkeletonLoader = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[1, 2].map((_, index) => (
            <div key={index} className="border border-gray-200 rounded-xl p-4 shadow-sm animate-pulse m-3">
              <div className="flex justify-between items-center mb-2">
                <div className="h-6 bg-gray-200 rounded w-3/4"></div>
                <div className="h-5 w-5 bg-gray-200 rounded-full"></div>
              </div>
              <div className="h-4 bg-gray-200 rounded w-1/2 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-full mb-4"></div>
              <div className="flex space-x-2">
                <div className="h-9 bg-gray-200 rounded-lg w-24"></div>
                <div className="h-9 bg-gray-200 rounded-lg w-20"></div>
              </div>
            </div>
          ))}
        </div>
      );
};

export default FormCardSkeletonLoader;