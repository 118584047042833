import React from 'react';

const MultipleChoiceGrid = ({ question, gridAnswers, handleMultipleChoiceGridSelection,svgColor }) => {
    return (
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="w-1/3 text-left py-2 px-4"></th>
            {question.options.map((option, index) => (
              <th key={`column-${index}`} className="text-center py-2 px-4 font-medium">
                {option.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {question.rows.map((row) => (
            <tr key={row.entry}>
              <td className="py-1 px-4 font-medium">{row.value}</td>
              {question.options.map((option) => (
                <td key={`${row.entry}-${option.value}`} className="text-center py-1 px-4">
                  <label className="inline-block relative w-5 h-5">
                    <input
                      type="radio"
                      name={row.entry}
                      value={option.value}
                      checked={gridAnswers[row.entry] === option.value}
                      onChange={() => handleMultipleChoiceGridSelection(question.entry, row.entry, option.value)}
                      className="absolute opacity-0 w-0 h-0"
                    />
                    <span className="absolute top-0 left-0 w-5 h-5 border border-gray-400 rounded-full cursor-pointer">
                      {gridAnswers[row.entry] === option.value && (
                        <svg className="absolute top-0 left-0 w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={svgColor}>
                          <circle cx="10" cy="10" r="10" />
                          <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </label>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
};

export default MultipleChoiceGrid;