export const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

export const validatePhoneNumber = (phoneNumber) => {
  const re = /^\+?[1-9]\d{1,14}$/;
  return re.test(phoneNumber);
};

export const validateField = (type, value) => {
  if (!value) return null; // Don't validate empty fields
  switch (type) {
    case 'email':
      return validateEmail(value) ? null : 'Invalid email address';
    case 'tel':
      return validatePhoneNumber(value) ? null : 'Invalid phone number';
    default:
      return null;
  }
};