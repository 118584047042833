import React, { useState, useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import useApi from "../utils/api";

const AdminLayout = () => {
  const { user } = useUser();
  const email = user?.primaryEmailAddress?.emailAddress || "";
  const api = useApi();
  const [isAdmin, setIsAdmin] = useState(false);
  const [errorData, setErrorData] = useState(null);
  const [submissionData, setSubmissionData] = useState(null);

  // Set admin if the user's email matches
  useEffect(() => {
    if (['harshitvarma638@gmail.com', 'shubhambhatt.mailbox@gmail.com'].includes(email)) {
      setIsAdmin(true);
    }
  }, [email]);

  useEffect(() => {
    const getErrorData = async () => {
      try {
        const response = await api.get('/error-data');
        setErrorData(response.data);
        console.log(errorData);
      } catch (e) {
        console.log(e);
      }
    };
    
    // Only fetch error data if the user is an admin
    if (isAdmin) {
      getErrorData();
    }
  }, [isAdmin]);

  useEffect(() => {
    const getSubmissionData = async () => {
      try {
        const response = await api.get('/submission-data');
        setSubmissionData(response.data);
        console.log(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    // Only fetch submission data if the user is an admin
    if (isAdmin) {
      getSubmissionData();
    }
  }, [isAdmin]);

  return (
    <>
        {isAdmin && (
            <div className="space-y-8 w-full px-4">
                {/* Error Data Section */}
                <div>
                  <h2 className="text-xl font-semibold mb-2">Error Data</h2>
                  <p className="text-xl font-bold py-2">Total errors: {errorData?.length}</p>
                  {errorData && errorData.length > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                      {errorData.map((item) => (
                        <div
                          key={item.id}
                          className="bg-red-100 border border-red-300 rounded-lg p-4 shadow-md"
                        >
                          <div className="flex-1 flex-column">
                            <p className="font-bold text-red-700 truncate">Error Message: {item.message}</p>
                            <p className="text-sm truncate">Form ID: {item.formId}</p>
                            <p>Error Count: {item.count}</p>
                            <p>Error: {item.data[0].message}</p>
                          </div>
                          <div className="mt-2">
                            <p className="truncate">
                              Public URL:{" "}
                              <a
                                href={item.data[0].publicForm} rel="noreferrer" target="_blank"
                                className="text-blue-500 underline break-all"
                              >
                                {item.data[0].publicForm}
                              </a>
                            </p>
                            <p className="truncate">
                              Google Form:{" "}
                              <a
                                href={item.data[0].originalForm} rel="noreferrer" target="_blank"
                                className="text-blue-500 underline break-all"
                              >
                                {item.data[0].originalForm}
                              </a>
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className="text-gray-500">No errors found.</p>
                  )}
                </div>
                {/* Submission Data Section */}
                <div>
                <h2 className="text-xl font-semibold mb-2">Submission Data</h2>
                <p className="text-xl font-bold py-2">Total forms: {submissionData?.length}</p>
                {submissionData && submissionData.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {submissionData.map((item) => (
                      <div
                        key={item.id}
                        className="bg-green-100 border border-green-300 rounded-lg p-4 shadow-md"
                      >
                        <div className="flex-1">
                          <p className="font-bold text-green-700 truncate">Title: {item.data[0].title}</p>
                          <p className="font-semibold text-blue-600">Email: {item.data[0].email||'not available'}</p>
                          <p className="text-sm truncate">Form ID: {item.formId}</p>
                          <p>Submission Count: {item.count}</p>
                        </div>
                        <div className="mt-2">
                          <p className="truncate">
                            Public URL:{" "}
                            <a
                              href={item.data[0].enhancedForm.publicUrl} rel="noreferrer" target="_blank"
                              className="text-blue-500 underline break-all"
                            >
                              {item.data[0].enhancedForm.publicUrl}
                            </a>
                          </p>
                          <p className="truncate">
                            Google Form:{" "}
                            <a
                              href={item.data[0].originalForm.url} rel="noreferrer" target="_blank"
                              className="text-blue-500 underline break-all"
                            >
                              {item.data[0].originalForm.url}
                            </a>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500">No submissions found.</p>
                )}
              </div>
            </div>
        )}
    </>
  );
};

export default AdminLayout;
