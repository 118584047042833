import React, { useState,useEffect,useRef } from 'react';
import { HexColorPicker } from "react-colorful";
import { XCircle,Loader2,Check } from 'lucide-react';
import useApi from '../utils/api';

const CustomizableBox = ({userId,preferences}) => {
    const [buttonColor, setButtonColor] = useState(preferences?.buttonColor || '#000000');
    const [textColor, setTextColor] = useState(preferences?.textColor || '#ffffff');
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [radioChecked, setRadioChecked] = useState(true);
    const [checkboxChecked, setCheckboxChecked] = useState(true);
    const [hasChanged,setHasChanged] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [check,setCheck] = useState(false);
    const api = useApi();
    const modalRef = useRef(null);
    // const validateHex = (hex) => hex.length === 4 || hex.length === 7;
    //customization
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (showColorPicker && modalRef.current && !modalRef.current.contains(event.target)) {
            setShowColorPicker(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showColorPicker]);

    useEffect(() => {
        console.log(preferences);
        if(buttonColor !== preferences?.buttonColor || textColor !== preferences?.textColor){
            setHasChanged(true);
        }
        else{
            setHasChanged(false);
        }
    },[buttonColor,textColor,preferences]);

    useEffect(()=>{
        setTimeout(()=>{
            setButtonColor(preferences?.buttonColor);
            setTextColor(preferences?.textColor);
        },50);
    },[preferences]);

    const applyColorChange = async() => {
        try {
            setIsLoading(true);
            const response = await api.patch(`/edit-form-preferences/${userId}`, {
                preferences: {
                    buttonColor: buttonColor,
                    textColor: textColor,
                }
            });
            setIsLoading(false);
            setCheck(true);
            setTimeout(()=>{setCheck(false)}, 1500);
            window.location.reload();
            console.log('Preferences updated successfully:', response.data);
        } catch (error) {
            console.error('Error in applyColorChange:', error);
        }
    };

    const handleButtonColorInputChange = (e) => {
        setButtonColor(e.target.value);
    };

    const handleTextColorInputChange = (e) => {
        setTextColor(e.target.value);
    };

    const handleButtonColorPickerChange = (color) => {
        setButtonColor(color);
    };

    const handleTextColorPickerChange = (color) => {
        setTextColor(color);
    };

    return (
        <div className='bg-white shadow-sm rounded-xl border border-ng-100 overflow-hidden p-6'>
        <h3 className="mb-4 text-lg font-semibold">Customize Colors</h3>
            <div className="flex flex-row mb-6 gap-4">
                <div className="w-1/2">
                <p className="font-semibold mb-2">Button Color</p>
                <input
                    type="text"
                    className="border-2 p-1 w-full rounded-lg"
                    value={buttonColor}
                    onChange={handleButtonColorInputChange}
                    placeholder="#000000"
                />
                </div>

                <div className='w-1/2'>
                <p className="font-semibold mb-2">Text Color</p>
                <input
                    type="text"
                    className="border-2 p-1 w-full rounded-lg"
                    value={textColor}
                    onChange={handleTextColorInputChange}
                    placeholder="#ffffff"
                />
                </div>
            </div>

            <div className='flex flex-row gap-4'>
                <button
                    className={`p-2 rounded-md w-1/2 mb-4 flex items-center justify-center text-lg sm:text-xs md:text-sm lg:text-sm font-semibold h-8 sm:h-9 md:h-10 ${
                        hasChanged ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    }`}
                    onClick={applyColorChange}
                    disabled={isLoading ||
                        check || !hasChanged
                    }
                >
                    {isLoading ? (
                        <Loader2 className="w-6 h-6 animate-spin" />
                    ) : check ? (
                        <Check className="w-6 h-6 text-white" />
                    ) : (
                        "Apply"
                    )}
                </button>

                <button
                    className="bg-blue-600 text-white hover:bg-blue-700 p-2 rounded-md w-1/2 mb-4 text-lg sm:text-xs md:text-sm lg:text-sm font-semibold h-8 sm:h-9 md:h-10"
                    onClick={() => setShowColorPicker(!showColorPicker)}
                >
                    Color Palette
                </button>
            </div>

            {showColorPicker && (
                <div className="fixed inset-0 bg-black bg-opacity-20 z-50 flex items-center justify-center">
                <div ref={modalRef} className="bg-white p-8 rounded-lg shadow-lg relative max-w-lg w-full">
                <button
                    className="absolute top-4 right-4 p-2 rounded transition duration-200"
                    onClick={() => setShowColorPicker(false)}
                >
                    <XCircle className="w-8 h-8 text-gray-700" />
                </button>
                
                <h4 className="mb-6 text-xl font-semibold text-center text-gray-800">Select Colors</h4>
                
                <div className="flex justify-between mb-6">
                    <div className="w-auto pr-2">
                    <h4 className="mb-2 font-medium text-gray-700">Button Color</h4>
                    <HexColorPicker
                        color={buttonColor}
                        onChange={handleButtonColorPickerChange}
                        className="rounded-md"
                    />
                    </div>
            
                    <div className="w-auto pl-2">
                    <h4 className="mb-2 font-medium text-gray-700">Text Color</h4>
                    <HexColorPicker
                        color={textColor}
                        onChange={handleTextColorPickerChange}
                        className="rounded-md"
                    />
                    </div>
                </div>
                </div>
            </div>   
            )}

            <div className="preview mt-3">
                <div className="mt-3 flex justify-between items-center">
                <button
                    className="font-semibold py-2 px-4 rounded-md"
                    style={{ backgroundColor: buttonColor, color: textColor }}
                >
                    Next/Submit
                </button>

                <div className="flex items-center space-x-2">
                    <div className="flex items-center">
                        <label className="inline-block relative w-5 h-5">
                        <input
                            type="radio"
                            checked={radioChecked}
                            onChange={() => setRadioChecked(!radioChecked)}
                            className="absolute opacity-0 w-0 h-0"
                        />
                        <span className="absolute top-0 left-0 w-5 h-5 border border-gray-400 rounded-full cursor-pointer">
                            {radioChecked && (
                            <svg className="absolute top-0 left-0 w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={buttonColor}>
                                <circle cx="10" cy="10" r="10" />
                                <path
                                d="M6 10l3 3 5-5"
                                stroke={textColor}
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                            </svg>
                            )}
                        </span>
                        </label>
                        <p className="ml-2">Radio Button</p>
                    </div>

                    <div className="flex items-center">
                        <label className="inline-block relative h-5 w-5">
                        <input
                            type="checkbox"
                            checked={checkboxChecked}
                            onChange={() => setCheckboxChecked(!checkboxChecked)}
                            className="absolute opacity-0 w-0 h-0"
                        />
                        <span className="block h-5 w-5 border border-gray-400 rounded-sm cursor-pointer">
                            {checkboxChecked && (
                            <svg className="w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={buttonColor}>
                                <rect width="20" height="20" rx="2" />
                                <path
                                d="M6 10l3 3 5-5"
                                stroke={textColor}
                                strokeWidth="2"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                            </svg>
                            )}
                        </span>
                        </label>
                        <p className="ml-2">Checkbox</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default CustomizableBox;
