import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import bgImage from '../components/assets/bg.png'
import step1Image from '../components/assets/Step-1.png';
import step2Image from '../components/assets/Step-2.png';
import step3Image from '../components/assets/Step-3.png';
import upsellImage from '../components/assets/upsell.png';
import { motion, AnimatePresence } from 'framer-motion';


const HeroSection = () => {
  const word = "Interactive";
  
  return (
    <section className="relative min-h-[85vh] flex items-center overflow-hidden px-4 sm:px-0">
      <div
        className="absolute inset-0 opacity-80"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />

      <div className="container mx-auto relative z-10">
        <div className="flex flex-col items-center gap-4 sm:gap-6">
          <div className="inline-flex items-center px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-white shadow-sm border border-gray-200">
            <span className="text-[10px] sm:text-xs font-medium text-gray-500 tracking-wide">
              LIMITED TIME DEAL @50% DISCOUNT
            </span>
          </div>

          <div className="text-center">
          <h1 className="text-2xl sm:text-3xl lg:text-5xl font-bold tracking-tight leading-tight sm:leading-tight lg:leading-tight text-[#0A0A29] max-w-[22ch] mx-auto">
      Make Google Forms{' '}
      <span className="text-[#444FE9]">
        {word.split('').map((char, index) => (
          <motion.span
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.3,
              delay: index * 0.1,
              ease: "easeOut"
            }}
            className="inline-block"
          >
            {char}
          </motion.span>
        ))}
      </span>
      {' '}Like Typeform
    </h1>

            <p className="text-base sm:text-lg text-[#333333] mt-3 sm:mt-4 max-w-xl mx-auto px-4 sm:px-0">
              Transform plain Google Forms into sleek, interactive, and engaging experiences in minutes.
            </p>

            <Link
              to="/sign-in"
              className="inline-flex items-center justify-center px-4 sm:px-6 py-2 text-sm sm:text-base font-medium text-white bg-[#444FE9] border border-[#353CA6] rounded-[1000px] hover:bg-[#353CA6] transition-colors duration-200 shadow-[inset_0px_2px_1px_rgba(255,255,255,0.25)] mt-6 sm:mt-8"
            >
              Get started - It's free
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};


const DemoSection = () => {
  const [activeTab, setActiveTab] = useState('formlite');

  const containerVariants = {
    hidden: { 
      y: 100,
      opacity: 0 
    },
    visible: { 
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 100,
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="relative -mt-10 sm:-mt-20 px-4 sm:px-0">
      <div className="container mx-auto">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
        >
          <div className="flex justify-center mb-4">
            <div className="inline-flex rounded-xl bg-white p-1 shadow-sm border border-ng-100">
              <button
                onClick={() => setActiveTab('google')}
                className={`px-3 sm:px-6 py-2 rounded-md text-xs sm:text-sm font-medium transition-colors ${
                  activeTab === 'google'
                    ? 'bg-[#EEF3FB] text-gray-800'
                    : 'bg-white text-gray-600 hover:text-gray-900'
                }`}
              >
                Google form
              </button>
              <button
                onClick={() => setActiveTab('formlite')}
                className={`px-3 sm:px-6 py-2 rounded-md text-xs sm:text-sm font-medium transition-colors ${
                  activeTab === 'formlite'
                    ? 'bg-[#EEF3FB] text-gray-800'
                    : 'bg-white text-gray-600 hover:text-gray-900'
                }`}
              >
                Formlite
              </button>
            </div>
          </div>

          <div className="max-w-6xl mx-auto rounded-[20px] border border-[rgba(209,217,245,0.59)] bg-[#EEF3FB] shadow-[inset_-3px_-3px_0px_#FFF,inset_3px_3px_1px_rgba(255,255,255,0.62)] overflow-hidden">
            <div className="p-4 sm:p-6 flex items-center gap-2">
              <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#FF5F57]"></div>
              <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#FFBD2E]"></div>
              <div className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#28C840]"></div>
            </div>

            <motion.div 
              key={activeTab}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="bg-white h-[400px] sm:h-[600px] relative mx-2 sm:mx-4 mb-2 sm:mb-4 rounded-xl border border-ng-100 overflow-hidden"
            >
              {activeTab === 'google' && (
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfTHD7gCCy_eCX3DijAMeYEpOUaRlJ9mf9qSFSSWW25c5HCFQ/viewform"
                  className="w-full h-full border-none"
                  title="Google Form"
                />
              )}
              {activeTab === 'formlite' && (
                <iframe
                  src="https://www.formlite.co/public-form/1FAIpQLSfTHD7gCCy_eCX3DijAMeYEpOUaRlJ9mf9qSFSSWW25c5HCFQ?template=Typeform"
                  className="w-full h-full border-none"
                  title="Formlite Form"
                />
              )}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};





const HIWSection = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 100
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.2 // Stagger the animations of children
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 50
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="py-12 sm:py-20 px-4 sm:px-0">
      <div className="container mx-auto">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
        >
          <motion.h2 
            variants={itemVariants}
            className="text-xl sm:text-2xl font-semibold text-center mb-8 sm:mb-12"
          >
            How it works?
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8 max-w-6xl mx-auto">
            {[
              { image: step1Image, step: "STEP 1", title: "Add your form into Formlite" },
              { image: step2Image, step: "STEP 2", title: "Customize the style" },
              { image: step3Image, step: "STEP 3", title: "Share & collect responses" }
            ].map((item, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="bg-white rounded-xl border border-[rgba(209,217,245,0.59)] shadow-[0_4px_12px_rgba(0,0,0,0.03)]"
              >
                <div className="bg-[#F8F9FF] rounded-xl mb-2 overflow-hidden">
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-auto object-contain"
                  />
                </div>
                <div className="text-left p-3">
                  <p className="text-sm font-medium text-gray-400 mb-2">{item.step}</p>
                  <h3 className="text-base sm:text-lg font-medium">{item.title}</h3>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};


const FAQsection = () => {
  const [activeFaq, setActiveFaq] = useState(null);
  
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 100
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const answerVariants = {
    hidden: { 
      height: 0,
      opacity: 0 
    },
    visible: { 
      height: "auto",
      opacity: 1,
      transition: {
        height: {
          duration: 0.3,
          ease: "easeOut"
        },
        opacity: {
          duration: 0.2,
          delay: 0.1
        }
      }
    }
  };

  return (
    <section className="py-12 sm:py-20 px-4 sm:px-0">
      <div className="container mx-auto max-w-6xl">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
        >
          <motion.h2 
            variants={itemVariants}
            className="text-xl sm:text-2xl font-semibold text-center mb-8"
          >
            Frequently asked questions
          </motion.h2>

          <div className="space-y-4">
            {[
              {
                question: "Can I still collect responses in Google Sheets?",
                answer: "Yes, you can try us for free for 30 days. If you want, we'll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible."
              },
              {
                question: "Is Formlite free to use?",
                answer: "Content for this answer..."
              },
              {
                question: "How does Formlite differ from Typeform?",
                answer: "Content for this answer..."
              },
              {
                question: "Can I use custom branding?",
                answer: "Content for this answer..."
              }
            ].map((faq, index) => (
              <motion.div 
                key={index} 
                variants={itemVariants}
                className="border-b border-gray-200"
              >
                <button
                  onClick={() => setActiveFaq(activeFaq === index ? null : index)}
                  className="w-full py-4 sm:py-6 flex justify-between items-center text-left"
                >
                  <h3 className="text-lg sm:text-xl font-medium pr-4">{faq.question}</h3>
                  <motion.span 
                    animate={{ rotate: activeFaq === index ? 180 : 0 }}
                    transition={{ duration: 0.3 }}
                    className="text-xl sm:text-2xl flex-shrink-0"
                  >
                    {activeFaq === index ? '−' : '+'}
                  </motion.span>
                </button>
                <AnimatePresence initial={false}>
                  {activeFaq === index && (
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      exit="hidden"
                      variants={answerVariants}
                      className="overflow-hidden"
                    >
                      <div className="pb-4 sm:pb-6 text-[#4A4A68] text-sm sm:text-base">
                        {faq.answer}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </motion.div>
      </div>
    </section>
  );
};

const Upsellsection = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 100
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 30
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const imageVariants = {
    hidden: {
      opacity: 0,
      y: 60
    },
    visible: {
      opacity: 1,
      y: 1, // Keep the original translate-y-[1px]
      transition: {
        duration: 0.7,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="py-12 sm:py-20 relative px-4 sm:px-0">
      <div className="container mx-auto max-w-6xl">
        <motion.div 
          className="bg-white border border-ng-100 rounded-xl pt-12 sm:pt-20 pb-0 px-4 sm:px-8 relative"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
        >
          <div className="text-center max-w-2xl mx-auto">
            <motion.h2 
              variants={itemVariants}
              className="text-2xl sm:text-4xl font-bold mb-4"
            >
              Ready to transform your Google Forms?
            </motion.h2>
            
            <motion.p 
              variants={itemVariants}
              className="text-base sm:text-lg text-[#333333] mt-4 mb-4 max-w-2xl mx-auto"
            >
              Signup today and enhance your first google form today!
            </motion.p>

            <motion.div
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/sign-in"
                className="inline-flex items-center justify-center px-4 sm:px-6 py-3 sm:py-4 text-sm sm:text-base font-medium text-white bg-[#444FE9] border border-[#353CA6] rounded-[1000px] hover:bg-[#353CA6] transition-colors duration-200 shadow-[inset_0px_2px_1px_rgba(255,255,255,0.25)]"
              >
                Signup now
              </Link>
            </motion.div>
          </div>

          <motion.div 
            className="mt-8 sm:mt-16 max-w-4xl mx-auto"
            variants={imageVariants}
          >
            <img
              src={upsellImage}
              alt="Google Sheets to Online Store transformation"
              className="w-full h-auto"
            />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};


const Pricingsection = () => {
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 100
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        staggerChildren: 0.15
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 30
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 40,
      scale: 0.95
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="py-12 sm:py-20 px-4 sm:px-0">
      <div className="container mx-auto">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
          variants={containerVariants}
        >
          <motion.h2 
            variants={itemVariants}
            className="text-xl font-bold text-center mb-2 sm:mb-4"
          >
            Full access. One time payment
          </motion.h2>
          <motion.p 
            variants={itemVariants}
            className="text-center text-gray-600 mb-8 sm:mb-12"
          >
            Be an early adopter coz it has its own perks. 🦄
          </motion.p>

          <div className="max-w-6xl mx-auto grid md:grid-cols-2 border border-ng-100 rounded-xl overflow-hidden">
            <motion.div 
              variants={cardVariants}
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
              className="bg-white p-6 sm:p-8 shadow-sm"
            >
              <motion.h3 
                variants={itemVariants}
                className="text-xl sm:text-2xl font-bold mb-2"
              >
                Life time deal
              </motion.h3>
              <motion.p 
                variants={itemVariants}
                className="text-gray-600 mb-4 sm:mb-6"
              >
                2/30 sold. Hurry up
              </motion.p>

              <motion.div 
                variants={itemVariants}
                className="flex items-baseline mb-4 sm:mb-6"
              >
                <span className="text-4xl sm:text-5xl font-bold">$69</span>
                <span className="text-lg sm:text-xl text-gray-400 line-through ml-3">$99</span>
              </motion.div>

              <motion.p 
                variants={itemVariants}
                className="text-gray-600 mb-6 sm:mb-8"
              >
                One time payment
              </motion.p>

              <motion.div
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  to="/sign-in"
                  className="w-full inline-flex items-center justify-center px-4 sm:px-6 py-3 sm:py-4 text-sm sm:text-base font-medium text-white bg-[#444FE9] border border-[#353CA6] rounded-[1000px] hover:bg-[#353CA6] transition-colors duration-200 shadow-[inset_0px_2px_1px_rgba(255,255,255,0.25)]"
                >
                  Grab the deal!
                </Link>
              </motion.div>
            </motion.div>

            <motion.div 
              variants={cardVariants}
              className="bg-white p-6 sm:p-8 shadow-sm"
            >
              {[
                {
                  title: "What are the limits of free plan?",
                  content: "1 form with one template"
                },
                {
                  title: "What's included in one-time payment?",
                  content: "Access to current and upcoming features."
                },
                {
                  title: "Does it support private forms?",
                  content: "No, as of now we support public forms ideal for public facing use cases like surveys, polls, waitlists, quizzes etc."
                }
              ].map((item, index) => (
                <motion.div 
                  key={index} 
                  variants={itemVariants}
                  className={index !== 2 ? "mb-6 sm:mb-8" : ""}
                >
                  <h4 className="text-lg sm:text-xl font-semibold mb-2">
                    {item.title}
                  </h4>
                  <p className="text-gray-600 text-sm sm:text-base">
                    {item.content}
                  </p>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};


const Footersection = () => {
  const containerVariants = {
    hidden: {
      opacity: 0
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut"
      }
    }
  };

  const backgroundTextVariants = {
    hidden: {
      opacity: 0,
      scale: 1.1
    },
    visible: {
      opacity: 0.05,
      scale: 1,
      transition: {
        duration: 1.2,
        ease: "easeOut"
      }
    }
  };

  const linkHoverVariants = {
    hover: {
      x: 10,
      transition: {
        duration: 0.2,
        ease: "easeInOut"
      }
    }
  };

  return (
    <footer className="relative pt-20 sm:pt-40 pb-12 sm:pb-20 px-4 sm:px-0">
      <div className="absolute inset-0 w-full h-full flex items-center justify-center overflow-hidden">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={backgroundTextVariants}
          className="font-bold leading-none w-full text-center whitespace-nowrap"
          style={{
            fontSize: 'clamp(100px, 15vw, 300px)',
            background: 'linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          FORMLITE
        </motion.div>
      </div>

      <motion.div 
        className="container mx-auto relative z-10 max-w-6xl"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
        variants={containerVariants}
      >
        <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start gap-8">
          <motion.div 
            variants={itemVariants}
            className="text-gray-600 text-sm sm:text-base"
          >
            @ Formlite 2024
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="flex flex-col space-y-3 sm:space-y-4 text-center sm:text-left"
          >
            <motion.div whileHover="hover" variants={linkHoverVariants}>
              <Link
                to="/privacy-policy"
                className="text-gray-600 hover:text-gray-900 text-sm sm:text-base inline-block"
              >
                Privacy policy
              </Link>
            </motion.div>
            <motion.div whileHover="hover" variants={linkHoverVariants}>
              <Link
                to="/terms"
                className="text-gray-600 hover:text-gray-900 text-sm sm:text-base inline-block"
              >
                Terms & conditions
              </Link>
            </motion.div>
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="flex flex-col space-y-3 sm:space-y-4 text-center sm:text-left"
          >
            <motion.div whileHover="hover" variants={linkHoverVariants}>
              <a
                href="https://twitter.com/formlite"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-gray-900 text-sm sm:text-base inline-block"
              >
                Twitter
              </a>
            </motion.div>
            <motion.div whileHover="hover" variants={linkHoverVariants}>
              <a
                href="https://linkedin.com/company/formlite"
                target="_blank"
                rel="noopener noreferrer" 
                className="text-gray-600 hover:text-gray-900 text-sm sm:text-base inline-block"
              >
                LinkedIn
              </a>
            </motion.div>
          </motion.div>
        </div>
      </motion.div>
    </footer>
  );
};


const LandingPage = () => {
  return (
    // This div will specifically color the landing page background
    <div className="min-h-screen bg-[#F3F6FB]">
      <HeroSection />
      <DemoSection />
      <HIWSection />
      <Pricingsection />
      <FAQsection />
      <Upsellsection />
      <Footersection />
    </div>
  );
};

export default LandingPage;