import React, { useState,useEffect,useCallback,useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { validateField } from '../utils/validations';
import renderInputTF from '../utils/renderInputTF';
import { ChevronUp, ChevronDown, ArrowRight, CornerDownLeft, Loader2 } from 'lucide-react';

const Typeform = ({ sections,preferences, formInfo, onSubmit }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
//   const [files, setFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gridAnswers, setGridAnswers] = useState({});
  const [scrollDistance, setScrollDistance] = useState(0);
  const flatQuestions = sections.flat().filter((question) => question.type !== 'multi-section');
  const [scrolling, setScrolling] = useState(false);
  const prevQuestionIndex = useRef(currentQuestionIndex);
  
  useEffect(() => {
    prevQuestionIndex.current = currentQuestionIndex;
  }, [currentQuestionIndex]);

  const handleInputChange = (e, questionEntry) => {
    const { value, type, checked } = e.target;
    const question = sections.flatMap(section => section).find(q => q.entry === questionEntry);
    if (!question) {
      console.error(`Question not found for entry: ${questionEntry}`);
      return;
    }
  
    setAnswers(prevAnswers => {
      let newValue;
  
      switch (type) {
        case 'checkbox':
          // Handle checkbox inputs
          const prevValues = prevAnswers[questionEntry] || [];
          newValue = checked
            ? [...prevValues, value]
            : prevValues.filter(v => v !== value);
          break;
          
        case 'radio':
          // Handle radio inputs
          newValue = value === 'Other' ? '__other_option__' : value;
          if (value !== 'Other') {
            setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
          }
          break;
          
        default:
          newValue = value;
      }
  
      // Validate the new value
      const validationError = validateField(question.analyzedType, newValue);
      setErrors(prevErrors => ({
        ...prevErrors,
        [questionEntry]: validationError
      }));
  
      // Update state with new value
      return {
        ...prevAnswers,
        [questionEntry]: newValue
      };
    });
  };
  
  const handleOtherInputChange = (e, questionEntry) => {
    const { value } = e.target;
    setOtherAnswers(prevOtherAnswers => ({
      ...prevOtherAnswers,
      [questionEntry]: value
    }));
    setAnswers(prevAnswers => {
      const currentAnswer = prevAnswers[questionEntry];
      if (Array.isArray(currentAnswer)) {
        if (!currentAnswer.includes('__other_option__')) {
          return { ...prevAnswers, [questionEntry]: [...currentAnswer, '__other_option__'] };
        }
      } else if (currentAnswer !== '__other_option__') {
        return { ...prevAnswers, [questionEntry]: '__other_option__' };
      }
      return prevAnswers;
    });
  };

  const handleMultipleChoiceGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const newGridAnswers = {
        ...prev,
        [rowEntry]: option
      };
      console.log(newGridAnswers);
      return newGridAnswers;
    });
  };

  const handleCheckboxGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const currentSelections = prev[rowEntry] || [];
      if (currentSelections.includes(option)) {
        return { ...prev, [rowEntry]: currentSelections.filter(item => item !== option) };
      } else {
        return { ...prev, [rowEntry]: [...currentSelections, option] };
      }
    });
    console.log(gridAnswers);
  };

  // const handleFileUpload = (e, questionEntry) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setFiles(prev => ({ ...prev, [questionEntry]: file }));
  //     setAnswers(prevAnswers => ({
  //       ...prevAnswers,
  //       [questionEntry]: file.name
  //     }));
  //   }
  // }

  const handleSubmit = useCallback(async (e) => {
    if (e) e.preventDefault();
    const newErrors = {};
    let isValid = true;

    sections.flat().forEach(question => {
      const answer = answers[question.entry];
      console.log(question);
      if (question.required) {
        console.log(question);
        if (question.type === 'multiple_choice_grid') {
          const isGridComplete = question.rows.every((row) => {
            return gridAnswers[row.entry] !== undefined;
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'Please select an option for each row';
            isValid = false;
          }
        } else if (question.type === 'checkbox-grid') {
          const isGridComplete = question.rows.every((row) => {
            return Array.isArray(gridAnswers[row.entry]) && gridAnswers[row.entry].length > 0;
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'Please select at least one option for each row';
            isValid = false;
          }
        }
        else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.entry] = 'This question is required';
          isValid = false;
        }
      }

      // Validate email and phone number inputs
      if (answer && (question.analyzedType === 'email' || question.analyzedType === 'tel')) {
        const validationError = validateField(question.analyzedType, answer);
        if (validationError) {
          newErrors[question.entry] = validationError;
          isValid = false;
        }
      }
    });

    setErrors(newErrors);

    if (isValid) {
      setIsSubmitting(true);
      setErrors({});

      try {
        const finalAnswers = {};
        const submissionTimestamp = Date.now().toString();
        const payload = {
          ...finalAnswers,
          formId: formInfo.formId,
          fbzx: formInfo.fbzx,
          fvv: formInfo.fvv,
          pageHistory: formInfo.pageHistory,
          hud: formInfo.hud,
          partialResponse: JSON.stringify([null, null, formInfo.fbzx]),
          submissionTimestamp: submissionTimestamp,
        };
        Object.entries(answers).forEach(([questionId, answer]) => {
        if (Array.isArray(answer)) {
          // For checkbox and multiple choice questions
          const processedOptions = answer.map(option => option === 'Other' ? '__other_option__' : option)
                                        .filter(option => option !== 'Other');
          
          payload[questionId] = processedOptions;

          if (processedOptions.includes('__other_option__')) {
            payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
          }
        } else if (answer === 'Other' || answer === '__other_option__') {
          // For "other" options in radio
          payload[questionId] = '__other_option__';
          payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
        } else {
          // For all other question types
          payload[questionId] = answer;
        }
      });

      // Add grid answers
      Object.entries(gridAnswers).forEach(([rowEntry, value]) => {
        payload[rowEntry] = value;
      });

      // Convert array values to separate entries
      const finalPayload = {};
      Object.entries(payload).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            if (finalPayload[key]) {
              finalPayload[key] = [...finalPayload[key], item];
            } else {
              finalPayload[key] = [item];
            }
          });
        } else {
          finalPayload[key] = value;
        }
      });
  
        const success = await onSubmit(payload);

        if (success) {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Form submission failed. Please try again.'
        }));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Find the first invalid question and set it as current
      const firstInvalidIndex = sections.flat().findIndex(question => newErrors[question.entry]);
      if (firstInvalidIndex !== -1) {
        setCurrentQuestionIndex(firstInvalidIndex);
      }
    }
  }, [sections, answers, gridAnswers, formInfo, onSubmit,otherAnswers]);

  const handleCheckboxChange = (e, questionEntry) => {
    const { value, checked } = e.target;
    setAnswers(prev => {
      const currentAnswers = Array.isArray(prev[questionEntry]) ? prev[questionEntry] : [];
      if (checked) {
        return { ...prev, [questionEntry]: [...currentAnswers, value] };
      } else {
        return { ...prev, [questionEntry]: currentAnswers.filter(item => item !== value) };
      }
    });
    if (value === 'Other' && !checked) {
      setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
    }
  };

  const questions = sections.flat();
  const handleNext = useCallback(() => {
    const currentQuestion = questions[currentQuestionIndex];
    let isValid = true;
    let errorMessage = '';

    // Check if the question is required and answered
    if (currentQuestion.required) {
      const answer = answers[currentQuestion.entry];
      if (currentQuestion.type === 'multiple_choice_grid') {
        const isGridComplete = currentQuestion.rows.every((row) => {
          const rowEntry = row.entry || row;
          return gridAnswers[rowEntry] !== undefined;
        });

        if (!isGridComplete) {
          isValid = false;
          errorMessage = 'Please select an option for each row';
        }
      }
      else if (currentQuestion.type === 'checkbox-grid') {
        const isGridComplete = currentQuestion.rows.every((row) => {
          const rowEntry = row.entry || row;
          return Array.isArray(gridAnswers[rowEntry]) && gridAnswers[rowEntry].length > 0;
        });
        
        if(!isGridComplete) {
          isValid = false;
          errorMessage = 'Please select at least one option for each row';
        }
      }
      else if (currentQuestion.type === 'checkbox') {
        if (!answer || answer.length === 0) {
          isValid = false;
          errorMessage = 'This question is required';
        }
      }
      else if (currentQuestion.type === 'radio') {
        if (!answer ||answer.length === 0) {
          isValid = false;
          errorMessage = 'This question is required';
        }
      }
      else if (currentQuestion.type === 'time') {
        const timeAnswer = answers[currentQuestion.entry];
        console.log(timeAnswer);
        if (!timeAnswer) {
          isValid = false;
          errorMessage = 'Please enter a valid time (HH:MM AM/PM)';
        }
      }
      else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
        isValid = false;
        errorMessage = 'This question is required';
      }
    }

    // If it's valid so far and has a specific type (email or tel), validate it
    if (isValid && currentQuestion.analyzedType && (currentQuestion.analyzedType === 'email' || currentQuestion.analyzedType === 'tel')) {
      const validationError = validateField(currentQuestion.analyzedType, answers[currentQuestion.entry]);
      if (validationError) {
        isValid = false;
        errorMessage = validationError;
      }
    }

    if (!isValid) {
      setErrors(prev => ({ ...prev, [currentQuestion.entry]: errorMessage }));
    } else {
      setErrors(prev => ({ ...prev, [currentQuestion.entry]: null }));
      if (currentQuestionIndex < flatQuestions.length - 1) {
        prevQuestionIndex.current = currentQuestionIndex
        setCurrentQuestionIndex((prev) => Math.min(prev + 1, flatQuestions.length - 1));
      }
      else {
        handleSubmit();
      }
    }
  },[currentQuestionIndex, questions, answers, gridAnswers, handleSubmit, flatQuestions]);

  const handlePrevious = useCallback(() => {
    prevQuestionIndex.current = currentQuestionIndex;
    setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
  },[currentQuestionIndex]);

  const handleKeyDown = useCallback((e) => {
    const activeElement = document.activeElement;
    const isTextInput = activeElement.tagName === 'INPUT' || activeElement.tagName === 'TEXTAREA' || 
    (activeElement.tagName === 'INPUT' && activeElement.type === 'time');

    if(e.key === 'Enter' && isSubmitted){
      window.open('https://formlite.co');
    }
    
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (currentQuestionIndex === questions.length - 1) {
        if(!isSubmitted) handleSubmit();
      } else {
        handleNext();
      }
    }
    else if (e.key === 'Escape') {
      setAnswers(prev => ({ ...prev, [questions[currentQuestionIndex].entry]: undefined }));
      setOtherAnswers(prev => ({ ...prev, [questions[currentQuestionIndex].entry]: '' }));
      setGridAnswers(prev => {
        const newGridAnswers = { ...prev };
        questions[currentQuestionIndex].rows?.forEach(row => {
          delete newGridAnswers[row.entry];
        });
        return newGridAnswers;
      });
    }

    if(!isTextInput){
      if (e.key === 'ArrowRight') {
        if(currentQuestionIndex+1 !== flatQuestions.length) handleNext();
      } else if (e.key === 'ArrowLeft') {
        handlePrevious();
      } 
    }
  }, [handleNext, handlePrevious, handleSubmit, currentQuestionIndex, flatQuestions, questions, isSubmitted]);

  const handleScroll = useCallback((e) => {
    if (!scrolling) {
      setScrollDistance((prevScrollDistance) => prevScrollDistance + e.deltaY);
    }
  }, [scrolling]);

  const SCROLL_THRESHOLD = 450;
  const SCROLL_COOLDOWN = 500;

  useEffect(() => {
    if (scrollDistance >= SCROLL_THRESHOLD && !scrolling) {
      if (currentQuestionIndex + 1 !== flatQuestions.length) {
        handleNext();
      }
      setScrolling(true); // Set cooldown
      setTimeout(() => setScrolling(false), SCROLL_COOLDOWN); // Reset cooldown after timeout
      setScrollDistance(0);
    } else if (scrollDistance <= -SCROLL_THRESHOLD && !scrolling) {
      handlePrevious();
      setScrolling(true); // Set cooldown
      setTimeout(() => setScrolling(false), SCROLL_COOLDOWN); // Reset cooldown after timeout
      setScrollDistance(0);
    }
  }, [scrollDistance, scrolling, handleNext, currentQuestionIndex, flatQuestions, handlePrevious]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', handleScroll);
    };
  }, [handleKeyDown, handleScroll]);

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
          <h2 className="text-3xl font-bold mb-4 text-black">Thank you for your submission!</h2>
          <div className='flex flex-row items-center justify-center gap-2'>
            <a
              href="https://formlite.co"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Go to formlite
            </a>
            <p className="text-sm flex flex-row gap-1 items-center">
              press <b>Enter</b> 
              <span className="items-center">
                <CornerDownLeft size={12} />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if(formInfo.isLoginRequired){
      return (
        <div className="min-h-screen bg-white flex items-center justify-center p-4">
          <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
            <h2 className="text-2xl font-bold mb-4 text-black">Forms with sign in are not supported</h2>
            <div className='flex flex-row items-center justify-center gap-2'>
              <a
                href="https://formlite.co"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
              >
                Go to formlite
              </a>
              <p className="text-sm flex flex-row gap-1 items-center">
                press <b>Enter</b> 
                <span className="items-center">
                  <CornerDownLeft size={12} />
                </span>
              </p>
            </div>
          </div>
        </div>
      );
  }

  const currentQuestion = flatQuestions[currentQuestionIndex];
  const currentSection = sections.find(section => section.includes(currentQuestion));
  console.log("current section:", currentSection);
  return (
    <div className="h-screen flex flex-col overflow-hidden">
    <div className="w-full bg-gray-300 h-1.5">
        <div
        className="h-1.5 transition-all duration-300"
        style={{ backgroundColor: preferences?.buttonColor,width: `${((currentQuestionIndex + 1) / flatQuestions.length) * 100}%` }}
        ></div>
    </div>


    <div className="flex-grow bg-white flex items-center justify-center p-4 relative overflow-y-auto">
      <AnimatePresence mode="wait">
        <motion.div
          key={currentQuestionIndex}
          initial={{
            opacity: 0,
            y: prevQuestionIndex.current < currentQuestionIndex ? 100 : -100,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
            y: prevQuestionIndex.current < currentQuestionIndex ? -100 : 100,
          }}
          transition={{ duration: 0.4 }}
          className="max-w-3xl w-full flex items-center justify-center overflow-y-auto h-full"
        >
          <div className="flex-grow bg-white rounded-lg p-8 flex items-start space-x-4 max-h-full hide-scrollbar overflow-y-auto">
            <div className="flex items-center space-x-2">
              <p className="text-gray-600 text-2xl">{currentQuestionIndex + 1}</p>
              <ArrowRight size={24} />
            </div>

            <div className="flex flex-col space-y-4 w-full">
              {currentSection && currentSection[0].type === 'multi-section' && (
                <>
                  <h1 className="text-3xl font-bold mb-2 text-gray-800">{currentSection[0].question}</h1>
                  {currentSection[0].description && (
                    <p className="text-gray-600 mb-6">{currentSection[0].description}</p>
                  )}
                  <hr className="mb-6 border-gray-200" />
                </>
              )}

              <h2 className="text-2xl font-bold text-black">
                {currentQuestion.question}
                {currentQuestion.required && <span className="text-red-500 ml-1">*</span>}
              </h2>

              {currentQuestion.mediaContent && currentQuestion.mediaContent.type === 'image' && (
                <div className="w-full mb-4 flex justify-start max-h-[60vh] overflow-auto">
                  <img
                    src={currentQuestion.mediaContent.url}
                    alt={currentQuestion.mediaContent.alt}
                    className="max-w-full h-auto w-auto rounded-xs shadow-md object-contain"
                    style={{ maxHeight: '278px' }}
                  />
                </div>
              )}

              {currentQuestion.mediaContent && currentQuestion.mediaContent.type === 'video' && (
                <div className="relative w-full pt-[56.25%]">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full"
                    src={currentQuestion.mediaContent.youtubeUrl}
                    title={currentQuestion.mediaContent.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              )}

              <div className="w-full">
                {renderInputTF(
                  currentQuestion,
                  answers,
                  handleInputChange,
                  handleCheckboxChange,
                  otherAnswers,
                  handleOtherInputChange,
                  gridAnswers,
                  handleMultipleChoiceGridSelection,
                  handleCheckboxGridSelection,
                  preferences
                )}
              </div>

              <div className="flex flex-row">
                <button
                  onClick={handleNext}
                  style={{
                    backgroundColor: preferences?.buttonColor,
                    color: preferences?.textColor,
                  }}
                  className="flex flex-row items-center justify-center font-semibold py-2 px-3 text-xl rounded-md transition duration-300 self-start"
                >
                  {currentQuestionIndex + 1 === flatQuestions.length ? 'Submit' : 'OK'}
                  {isSubmitting ? <Loader2 size={20} className="ml-1" /> : ''}
                </button>

                <p className="text-sm flex-row gap-1 ml-2 items-center hidden lg:flex"> {/* Only show on medium screens and up */}
                  press <b>Enter</b>
                  <span className="items-center">
                    <CornerDownLeft size={12} />
                  </span>
                </p>
              </div>

              {errors[currentQuestion.entry] && (
                <p className="text-red-500 mt-2">{errors[currentQuestion.entry]}</p>
              )}
            </div>
          </div>

          {errors.submit && <p className="text-red-500 mt-4 text-center">{errors.submit}</p>}
        </motion.div>
      </AnimatePresence>
    </div>

    <div className="bg-white flex items-center justify-end p-4">
        <button
        onClick={handlePrevious}
        disabled={currentQuestionIndex === 0}
        style={{
            backgroundColor: preferences?.buttonColor,
            color: preferences?.textColor,
        }}
        className="p-2 rounded-l-md transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed border-r-[0.5px] border-gray-300"
        >
        <ChevronUp size={24} />
        </button>

        <button
        onClick={handleNext}
        disabled={isSubmitting || currentQuestionIndex+1 === flatQuestions.length}
        style={{
            backgroundColor: preferences?.buttonColor,
            color: preferences?.textColor,
        }}
        className="p-2 rounded-r-md transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
        >
        <ChevronDown size={24} />
        </button>

        <div className="flex items-center justify-center ml-2">
        <p className="font-400">
            Powered by{" "}
            <a
            href="https://formlite.co"
            target="_blank"
            rel="noreferrer"
            className="text-footer-text-blue ml-1"
            >
            Formlite
            </a>
        </p>
        </div>
    </div>
    </div>
);
    
};

export default Typeform;