import React from "react";

const CheckboxGrid = ({ question, gridAnswers, handleCheckboxGridSelection,svgColor }) => {
    // console.log('CheckboxGrid props:', { question, gridAnswers });
  
    if (!question.rows || !question.options || question.rows.length === 0 || question.options.length === 0) {
      console.error('Invalid question structure for CheckboxGrid:', question);
      return <p className="text-red-500">Error: Invalid question structure for checkbox grid</p>;
    }
  
    return (
      <table className="w-full">
        <thead>
          <tr>
            <th></th>
            {question.options.map((option, index) => (
              <th key={`column-${index}`} className="text-center py-2 font-medium">
                {option.value}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {question.rows.map((row) => (
            <tr key={row.entry}>
              <td className="px-4 py-1 font-medium">{row.value}</td>
              {question.options.map((option) => (
                <td key={`${row.entry}-${option.value}`} className="text-center">
                  <div className="relative inline-block">
                    <input
                      type="checkbox"
                      name={row.entry}
                      value={option.value}
                      checked={(gridAnswers[row.entry] || []).includes(option.value)}
                      onChange={() => handleCheckboxGridSelection(question.entry, row.entry, option.value)}
                      className="opacity-0 absolute h-5 w-5 cursor-pointer"
                    />
                    <span className="block h-5 w-5 border border-gray-400 rounded-sm cursor-pointer">
                      {(gridAnswers[row.entry] || []).includes(option.value) && (
                        <svg className="w-5 h-5 pointer-events-none" viewBox="0 0 20 20" fill={svgColor}>
                          <rect width="20" height="20" rx="2" />
                          <path d="M6 10l3 3 5-5" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
};

export default CheckboxGrid;