import React, { useState, useCallback, useEffect } from 'react';
import { CornerDownLeft } from 'lucide-react';
import { validateField } from '../utils/validations';
import renderInput from '../utils/renderInput';

const ClassicTemplate = ({ sections,preferences, formInfo, onSubmit }) => {
  const [answers, setAnswers] = useState({});
  // const [files, setFiles] = useState({});
  const [otherAnswers, setOtherAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gridAnswers, setGridAnswers] = useState({});
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const handleInputChange = (e, questionEntry) => {
    const { name, value, type, checked } = e.target;
    const question = sections.flatMap(section => section).find(q => q.entry === questionEntry);
    if (!question) {
      console.error(`Question not found for entry: ${questionEntry}`);
      return;
    }

    setAnswers(prevAnswers => {
      let newValue;

      switch (type) {
        case 'checkbox':
          const prevValues = prevAnswers[questionEntry] || [];
          newValue = checked
            ? [...prevValues, value]
            : prevValues.filter(v => v !== value);
          break;
          
        case 'radio':
          newValue = value === 'Other' ? '__other_option__' : value;
          if (value !== 'Other') {
            setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
          }
          break;
          
        default:
          newValue = value;
      }
      const validationError = validateField(question.analyzedType, newValue);
      setErrors(prevErrors => ({
        ...prevErrors,
        [questionEntry]: validationError
      }));
      return {
        ...prevAnswers,
        [questionEntry]: newValue
      };
    });
  };
  
  const handleOtherInputChange = (e, questionEntry) => {
    const { value } = e.target;
    setOtherAnswers(prevOtherAnswers => ({
      ...prevOtherAnswers,
      [questionEntry]: value
    }));
    setAnswers(prevAnswers => {
      const currentAnswer = prevAnswers[questionEntry];
      if (Array.isArray(currentAnswer)) {
        if (!currentAnswer.includes('__other_option__')) {
          return { ...prevAnswers, [questionEntry]: [...currentAnswer, '__other_option__'] };
        }
      } else if (currentAnswer !== '__other_option__') {
        return { ...prevAnswers, [questionEntry]: '__other_option__' };
      }
      return prevAnswers;
    });
  };

  const handleMultipleChoiceGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const newGridAnswers = {
        ...prev,
        [rowEntry]: option
      };
      console.log(newGridAnswers);
      return newGridAnswers;
    });
  };

  const handleCheckboxGridSelection = (questionEntry, rowEntry, option) => {
    setGridAnswers(prev => {
      const currentSelections = prev[rowEntry] || [];
      if (currentSelections.includes(option)) {
        return { ...prev, [rowEntry]: currentSelections.filter(item => item !== option) };
      } else {
        return { ...prev, [rowEntry]: [...currentSelections, option] };
      }
    });
    console.log(gridAnswers);
  };

  // const handleFileUpload = (e, questionEntry) => {
  //   const file = e.target.files[0];
  //   if(file) {
  //     setFiles(prev => ({ ...prev, [questionEntry]: file }));
  //     setAnswers(prevAnswers => ({
  //       ...prevAnswers,
  //       [questionEntry]: file.name
  //     }));
  //   }
  // }

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    const newErrors = {};
    let isValid = true;
  
    sections.flat().forEach(question => {
      const answer = answers[question.entry];
  
      if (question.required) {
        if (question.type === 'multiple_choice_grid' || question.type === 'checkbox-grid') {
          const isGridComplete = question.rows.every((row, index) => {
            const key = `${question.entry}-${index}`;
            return gridAnswers[key] && (
              question.type === 'multiple_choice_grid' ?
              gridAnswers[key] !== undefined :
              gridAnswers[key].length > 0
            );
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'This question is required';
            isValid = false;
          }
        } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.entry] = 'This question is required';
          isValid = false;
        }
      }
  
      // Validate email and phone number inputs
      if (answer && (question.analyzedType === 'email' || question.analyzedType === 'tel')) {
        const validationError = validateField(question.analyzedType, answer);
        if (validationError) {
          newErrors[question.entry] = validationError;
          isValid = false;
        }
      }
    });
  
    setErrors(newErrors);
  
    if (isValid) {
      setIsSubmitting(true);
      setErrors({});
  
      try {
        const finalAnswers = {};
        const submissionTimestamp = Date.now().toString();
        const payload = {
          ...finalAnswers,
          fbzx: formInfo.fbzx,
          fvv: formInfo.fvv,
          pageHistory: formInfo.pageHistory,
          hud: formInfo.hud,
          partialResponse: JSON.stringify([null, null, formInfo.fbzx]),
          submissionTimestamp: submissionTimestamp,
        };
        Object.entries(answers).forEach(([questionId, answer]) => {
          if (Array.isArray(answer)) {
            // For checkbox and multiple choice questions
            const processedOptions = answer.map(option => option === 'Other' ? '__other_option__' : option)
                                          .filter(option => option !== 'Other');
            
            payload[questionId] = processedOptions;
  
            if (processedOptions.includes('__other_option__')) {
              payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
            }
          } else if (answer === 'Other' || answer === '__other_option__') {
            // For "other" options in radio
            payload[questionId] = '__other_option__';
            payload[`${questionId}.other_option_response`] = otherAnswers[questionId] || '';
          } else {
            // For all other question types
            payload[questionId] = answer;
          }
        });
  
        // Add grid answers
        Object.entries(gridAnswers).forEach(([rowEntry, value]) => {
          payload[rowEntry] = value;
        });
  
        // Convert array values to separate entries
        const finalPayload = {};
        Object.entries(payload).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              if (finalPayload[key]) {
                finalPayload[key] = [...finalPayload[key], item];
              } else {
                finalPayload[key] = [item];
              }
            });
          } else {
            finalPayload[key] = value;
          }
        });
        
        const success = await onSubmit(payload);
  
        if (success) {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        setErrors(prev => ({
          ...prev,
          submit: 'Form submission failed. Please try again.'
        }));
      } finally {
        setIsSubmitting(false);
      }
    }
  },[sections, answers, gridAnswers, formInfo, onSubmit,otherAnswers]);

  const validateSection = useCallback((sectionQuestions) => {
    const newErrors = {};
    let isValid = true;

    sectionQuestions.forEach(question => {
      if (question.required) {
        const answer = answers[question.entry];
        if (question.type === 'multiple_choice_grid' || question.type === 'checkbox-grid') {
          const isGridComplete = question.rows.every((row, index) => {
            const key = `${question.entry}-${index}`;
            return gridAnswers[key] && (
              question.type === 'multiple_choice_grid' ?
              gridAnswers[key] !== undefined :
              gridAnswers[key].length > 0
            );
          });
          if (!isGridComplete) {
            newErrors[question.entry] = 'This question is required';
            isValid = false;
          }
        } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          newErrors[question.entry] = 'This question is required';
          isValid = false;
        }
      }

      // Validate email and phone number inputs
      if (answers && (question.analyzedType === 'email' || question.analyzedType === 'tel')) {
        const validationError = validateField(question.analyzedType, answers);
        if (validationError) {
          newErrors[question.entry] = validationError;
          isValid = false;
        }
      }
    });

    setErrors(prevErrors => ({ ...prevErrors, ...newErrors }));
    return isValid;
  },[answers,gridAnswers]);

  const handleNextSection = useCallback((e) => {
    e.preventDefault();
    const currentSectionQuestions = sections[currentSectionIndex];
    if (validateSection(currentSectionQuestions)) {
      setCurrentSectionIndex(prevIndex => 
        prevIndex < sections.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else {
      // Optionally, you can add a message to inform the user that all required fields must be filled
      console.log("Please fill all required fields before proceeding.");
    }
  }, [sections, currentSectionIndex,validateSection]);

  const handlePreviousSection = useCallback((e) => {
    e.preventDefault();
    setCurrentSectionIndex(prevIndex => prevIndex > 0 ? prevIndex - 1 : prevIndex);
  }, []);

  const handleKeyDown = useCallback((e) => {
    const activeElement = document.activeElement;
    const isTextInput = ['INPUT', 'TEXTAREA'].includes(activeElement.tagName) || 
      (activeElement.tagName === 'INPUT' && activeElement.type === 'time');

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (currentSectionIndex === sections.length - 1) {
        handleSubmit(e);
      } else {
        handleNextSection(e);
      }
    }

    if (!isTextInput) {
      if (e.key === 'ArrowRight') {
        handleNextSection(e);
      } else if (e.key === 'ArrowLeft') {
        handlePreviousSection(e);
      } 
    }
  }, [handleNextSection, handlePreviousSection, handleSubmit, currentSectionIndex, sections.length]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleCheckboxChange = (e, questionEntry) => {
    const { value, checked } = e.target;
    setAnswers(prev => {
      const currentAnswers = Array.isArray(prev[questionEntry]) ? prev[questionEntry] : [];
      if (checked) {
        return { ...prev, [questionEntry]: [...currentAnswers, value] };
      } else {
        return { ...prev, [questionEntry]: currentAnswers.filter(item => item !== value) };
      }
    });
    if (value === 'Other' && !checked) {
      setOtherAnswers(prev => ({ ...prev, [questionEntry]: '' }));
    }
  };
  // if (!questions || questions.length === 0 || !formInfo) {
  //   console.error('Missing required props in ClassicTemplate:', { questions, formInfo });
  //   return <div className="text-red-500">Error: Missing form data</div>;
  // }

  if (isSubmitted) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
          <h2 className="text-3xl font-bold mb-4 text-black">Thank you for your submission!</h2>
          <div className='flex flex-row items-center justify-center gap-2'>
            <a
              href="https://formlite.co"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Go to formlite
            </a>
            <p className="text-sm flex flex-row gap-1 items-center">
              press <b>Enter</b> 
              <span className="items-center">
                <CornerDownLeft size={12} />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if(formInfo.isLoginRequired){
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
          <h2 className="text-2xl font-bold mb-4 text-black">Forms with sign in are not supported</h2>
          <div className='flex flex-row items-center justify-center gap-2'>
            <a
              href="https://formlite.co"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Go to formlite
            </a>
            <p className="text-sm flex flex-row gap-1 items-center">
              press <b>Enter</b> 
              <span className="items-center">
                <CornerDownLeft size={12} />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }

  if (!sections || sections.length === 0 || !formInfo) {
    console.error('Missing required props in ClassicTemplate:', { sections, formInfo });
    return <div className="text-red-500">Error: Missing form data</div>;
  }

  const currentSection = sections[currentSectionIndex];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-md shadow-md p-8">
          <h1 className="text-3xl font-bold mb-2 text-gray-800">{formInfo.title}</h1>
          {formInfo.description && (
            <>
              <p className="text-gray-600 mb-6">{formInfo.description}</p>
              <hr className="mb-6 border-gray-200" />
            </>
          )}
          <form onSubmit={handleSubmit}>
            <div className="space-y-6">
              {currentSection.map((question) => (
                <div key={question.id}>
                  {question.type === 'multi-section' ? (
                    <>
                    <h2 className="text-2xl font-semibold mb-2">{question.question}</h2>
                    <p className='text-lg font-normal mb-0'>{question.description}</p>
                    </>
                  ) : (
                    <>
                      <label className="block text-lg font-medium text-gray-700 mb-3">
                        {question.question}
                        {question.required && <span className="text-red-500 ml-1">*</span>}
                      </label>
                      {question.mediaContent && question.mediaContent.type === 'image' && (
                      <div className="w-full mb-4 flex justify-center">
                          <img
                            src={question.mediaContent.url}
                            alt={question.mediaContent.alt}
                            className="max-w-full h-auto rounded-lg shadow-md object-contain"
                            style={{ maxHeight: '60vh' }}
                          />
                        </div>
                      )}
                      {question.mediaContent && question.mediaContent.type === 'video' && (
                        <div className="relative w-full pt-[56.25%]">
                          <iframe
                            className="absolute top-0 left-0 w-full h-full"
                            src={question.mediaContent.youtubeUrl}
                            title={question.mediaContent.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      )}
                      {renderInput(question, answers, handleInputChange, handleCheckboxChange, otherAnswers, handleOtherInputChange, gridAnswers, handleMultipleChoiceGridSelection, handleCheckboxGridSelection, preferences)}
                      {errors[question.entry] && (
                        <p className="text-red-500 text-sm mt-2">{errors[question.entry]}</p>
                      )}
                    </>
                  )}
                </div>
              ))}
              <div className="flex justify-between mt-8">
                <button
                  type="button"
                  onClick={handlePreviousSection}
                  style={{
                    backgroundColor: preferences.buttonColor,
                    color: preferences.textColor,
                    opacity: currentSectionIndex === 0 ? 0.5 : 1,
                  }}
                  className="py-2 px-4 rounded-md transition duration-300"
                  disabled={currentSectionIndex === 0}
                >
                  Previous
                </button>
                {currentSectionIndex < sections.length - 1 ? (
                  <button
                    type="button"
                    onClick={handleNextSection}
                    style={{
                      backgroundColor: preferences.buttonColor,
                      color: preferences.textColor,
                    }}
                    className="py-2 px-4 rounded-md transition duration-300"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="submit"
                    style={{
                      backgroundColor: preferences.buttonColor,
                      color: preferences.textColor,
                    }}
                    className="py-2 px-4 rounded-md transition duration-300"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                )}
              </div>
            </div>
          </form>
          <p className="flex items-center justify-center font-400 mt-3">Powered by <a href="https://formlite.co" target="_blank" rel="noreferrer" className="text-footer-text-blue ml-1"> Formlite</a></p>
        </div>
      </div>
    </div>
  );
};

export default ClassicTemplate;
