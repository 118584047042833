import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { UserButton, useUser, useAuth } from '@clerk/clerk-react';
import logo from './assets/logo.png';
import PricingModal from './common/Pricing';
import useApi from '../utils/api';
import Pro from "../components/assets/pro.png";

const Navbar = (props) => {
  const { user } = useUser();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPro, setIsPro] = useState(false);
  const {getToken} = useAuth();
  const token = getToken();
  const api = useApi();

  useEffect(() => {
    const checkSubscription = async () => {
      const response = await api.get(`/check-subscription/${user.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      if(!response){
        throw new Error("failed to fetch pro status");
      }
      const status = response.data.status;
      setIsPro(status);
    };

    if(props.isSignedIn) checkSubscription();
  }, [setIsPro,user,token])

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
<nav className="h-[60px] bg-transparent border-none px-4 sm:px-6 py-3 flex justify-between items-center absolute top-0 left-0 right-0 z-50">
 <div className="container mx-auto max-w-6xl flex justify-between items-center">
   <div className="flex items-center">
     <Link to="/">
       <img src={logo} alt="Formlite Logo" className="h-6 w-auto mr-2" />
     </Link>
     {isPro && <img src={Pro} alt="pro-logo" className="h-5 w-auto"/>}
   </div>

   <div className="flex items-center gap-4">
     <a href="https://x.com/s4tr2" className="text-gray-600 hover:text-purple-600" target='_blank'>Help</a>
     {props.isSignedIn === undefined ? null : props.isSignedIn ? (
       <>
         {!isPro && <button onClick={handleModalToggle} className="bg-indigo-600 text-white hover:bg-indigo-700 text-sm font-medium py-2 px-4 rounded">
           Upgrade
         </button>}
         <PricingModal isOpen={isModalOpen} onClose={handleModalToggle} />
         <div className="min-w-[36px] flex items-center justify-center">
           <UserButton />
         </div>
       </>
     ) : (
       <Link to="/sign-in">
         <button className="inline-flex items-center justify-center px-4 sm:px-6 py-2 sm:py-2 text-sm sm:text-sm font-medium text-white bg-[#444FE9] border border-[#353CA6] rounded-[1000px] hover:bg-[#353CA6] transition-colors duration-200 shadow-[inset_0px_2px_1px_rgba(255,255,255,0.25)]">
           Login/Signup
         </button>
       </Link>
     )}
   </div>
 </div>
</nav>
  );
};

export default Navbar;
