import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useApi from '../utils/api';
import ClassicTemplate from './ClassicTemplate';
import TypeformTemplate from './TypeformTemplate';
// import ConversationalTemplate from './ConversationalTemplate';
import { validateField } from '../utils/validations';
import Lottie from 'lottie-react';
import confettiAnimationData from './assets/confetti.json';
import Typeform from './Typeform';
import { CornerDownLeft } from 'lucide-react';

const PublicForm = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const { formId } = useParams();
  const [searchParams] = useSearchParams();
  const [userPreferences,setUserPreferences] = useState(null);
  const template = searchParams.get('template') || 'classic';
  const api = useApi();

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        setLoading(true);

        const response = await api.get(`/fetch-form?formId=${formId}`);

        if (!response.data) {
          throw new Error('No data received from server');
        }


        setFormData(response.data);
      } catch (err) {
        console.error('Error in fetchFormData:', err);
        setError(`Failed to load form data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    if (formId) {
      fetchFormData();
    } else {
      setError('No form ID provided');
      setLoading(false);
    }
  }, [formId]);

  useEffect(() => {
    const getPreferences = async () => {
      try{
        const preferences = await api.get(`/user-preferences/${formId}`);
        if(!preferences){
          console.error('Error fetching user preferences');
        }
        setUserPreferences(preferences.data.preferences);
      }
      catch(error){
        console.error('Error fetching user preferences:', error);
      }
    };

    getPreferences();
  },[formId,setUserPreferences]);

  const handleEnter = (e) => {
    if(e.key === 'Enter' && isSubmitted){
      window.open('https://formlite.co');
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEnter);
  })

  const handleSubmit = async (submissionData) => {
    try {
      const response = await api.post(`/submit-form/${formId}`, submissionData);

      if (response.data.success) {
        setIsSubmitted(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 4500);
        return true;
      } else {
        console.error('Form submission failed');
        setError('Form submission failed. Please try again.');
        return false;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError(`Error submitting form: ${error.message}`);
      return false;
    }
  };

  const renderForm = () => {
    const props = {
      sections: formData.sections,
      preferences: userPreferences,
      formInfo: {
        formId: formData.formId,
        title: formData.title,
        description: formData.description,
        fbzx: formData.fbzx,
        fvv: formData.fvv,
        pageHistory: formData.pageHistory,
        hud: formData.hud
      },
      onSubmit: handleSubmit,
      validateField: validateField
    };

    switch (template) {
      case 'Classic':
        return <ClassicTemplate {...props} />;
      case 'Interactive':
        return <TypeformTemplate {...props} />;
      case 'Typeform':
        return <Typeform {...props} />;
      default:
        return <ClassicTemplate {...props} />;
    }
  };


  if (loading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <div className='flex flex-col mb-3 items-center justify-center'>
          <p className='text-sm'>powered by</p>
          <p className='text-2xl font-bold'>Formlite</p>
        </div>
        <div className="w-[150px] h-1 bg-gray-300 relative overflow-hidden rounded-lg">
          <div className="absolute h-2 bg-black animate-growShrinkLoader rounded-lg"></div>
        </div>
      </div>
    );
  }  

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-xl font-semibold text-red-600">Error: {error}</div>
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="text-xl font-semibold text-gray-800">No form data available.</div>
      </div>
    );
  }

  if(formData?.isLoginRequired){
    return (
      <div className="min-h-screen bg-white flex items-center justify-center p-4">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
          <h2 className="text-2xl font-bold mb-4 text-black">Forms with sign in are not supported</h2>
          <div className='flex flex-row items-center justify-center gap-2'>
            <a
              href="https://formlite.co"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Go to formlite
            </a>
            <p className="text-sm flex flex-row gap-1 items-center">
              press <b>Enter</b> 
              <span className="items-center">
                <CornerDownLeft size={12} />
              </span>
            </p>
          </div>
        </div>
      </div>
    );
}

  if (isSubmitted) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100">
        <div className="bg-gray-50 rounded-xl border border-ng-100 p-8 max-w-lg w-full text-center items-center justify-center">
          <h2 className="text-3xl font-bold mb-4 text-black">Thank you for your submission!</h2>
          <div className='flex flex-row items-center justify-center gap-2'>
            <a
              href="https://formlite.co"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
            >
              Go to formlite
            </a>
            <p className="text-sm flex flex-row gap-1 items-center">
              press <b>Enter</b> 
              <span className="items-center">
                <CornerDownLeft size={12} />
              </span>
            </p>
          </div>
        </div>
        {showConfetti && (
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
            zIndex: 9999,
          }}>
            <Lottie
              animationData={confettiAnimationData}
              loop={false}
              autoplay={true}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="h-full w-full bg-white">
      {/* <div className="max-w-3xl mx-auto bg-white rounded-lg overflow-hidden"> */}
        {renderForm()}
      {/* </div> */}
    </div>
  );
};

export default PublicForm;
